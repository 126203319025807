function Footer(){
  return(
    <footer>
      <div className="container">
        <p>© AJ Fillari</p>
        <div className="links">
          <a href="/#work">Work</a>
          <a href="/about">About</a>
        </div>
      </div>
    </footer>
  )
}
export default Footer;
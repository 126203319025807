import aj from "../assets/aj.jpg"
import './About.scss'
function About(){
  return(
    <div className="container small about">
      <img alt="aj!" src={aj}/>
      <div className="description">
        <h1>About me</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
      </div>
    </div>
  )
}

export default About
import aj from "../assets/aj.svg"
function Nav() {
  return(
    <nav>
      <div className="container">
        <a href="/"><img alt="logo" src={aj}/></a>
        <a href="/#work">Work</a>
        <a href="/about">About</a>
      </div>
    </nav>
  )
}

export default Nav;
import { useEffect, useState } from "react"
import rect from "../assets/rect.png"
import './Show.scss'
function Show({show}){
  const {rssLink} = show
  const [audioData, setAudioData] = useState('')
  const [episodeTitle, setEpisodeTitle] = useState('')
  const [doneLoading, setDoneLoading] = useState(false)
  const fetchRssContent = () => {
    setDoneLoading(false)
    try {
        fetch(`/.netlify/functions/get-rss-show?showLink=${rssLink}`)
        .then((data)=>{
          return data.json()
        }).then((res)=>{
          setAudioData(res.link)
          setEpisodeTitle(res.title)
        })
    }
    catch(err){
      console.log(err)
      setDoneLoading(true)
    }
  }
  const setAudioDone = function(){
    setDoneLoading(true)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchRssContent,[]) 
    return(
      <div target="_blank" rel="noreferrer" href={show.link} className="work-item">
        <img src={show.img} alt={show.title}/>
        <div className="work-content">
          <div>
            <h3>{show.title}</h3>
            <img className="rect" alt="rectangle" src={rect}/>
            <p>{show.description}</p>
          </div>
            <div className={`audio-player ${doneLoading ? 'show' : 'hide'}`}>
              <h4>{episodeTitle}</h4>
              <audio onCanPlay={setAudioDone} src={audioData} controls></audio>
            </div>
            <div className={`loading ${!doneLoading ? 'show' : 'hide'}`}>
              <div class="lds-ripple"><div></div><div></div></div><span>Loading episode...</span>
            </div>
        </div>
      </div>
    )
}

export default Show;
import Hero from "../components/Hero"
import Show from "../components/Show"
import ita from "../assets/ita.jpg"
import cant from "../assets/cant.webp"
import tvbb from "../assets/tvbb.jpg"
import frog from "../assets/frog.jpg"
import dotzip from "../assets/dotzip.jpg"
import well from "../assets/well.jpg"
import vgp from "../assets/vgp.jpg"
import rect from "../assets/rect.png"
const shows = [
{
  title: 'Into the Aether',
  link:'https://intothecast.online/',
  img:ita,
  description:'45k downloads/month. Produce, edit and occasionally guest host this weekly "lowkey video game podcast"',
  rssLink:'https://feeds.transistor.fm/intotheaether'
},
{
  title: 'Frog of the Week',
  link:'https://frogpod.online',
  img:frog,
  description:'10k downloads/month',
  rssLink: 'https://feeds.transistor.fm/frogoftheweek'
},
{
  title: 'dotzip',
  link:'https://dotzip.online',
  img:dotzip,
  description:'3k downloads/month',
  rssLink: 'https://feeds.transistor.fm/dotzip'
},
{
  title: 'The Well-Trained Mind Podcast',
  link:'https://welltrainedmind.com/the-well-trained-mind-podcast/',
  img:well,
  description:'10k downloads/month',
  rssLink:'https://feeds.transistor.fm/the-well-trained-mind-podcast'
}]

const projects = [{
  title:"Can't Let It Go",
  link:'https://cantletitgo.gay/episode/7c698f7c/because-internet-with-aj-',
  img: cant,
  description:'Guest hosted and edited this episode about how the internet has changed human communication'
},
{
  title:"Video Game Podtimism",
  link:'https://podcasters.spotify.com/pod/show/podtimism/episodes/Ep--197-Kingdom-Hearts-Chain-of-Memories-e2ic2kp',
  img: vgp,
  description:'Guested on this deep-dive into Kingdom Heart: Chain of Memories'
},
{
  title:"Ten Very Big Books - A Malazan Readthrough Podcast",
  link:'https://feeds.transistor.fm/tenverybigbooks',
  img: tvbb,
  description:'Cohosted, produced, and edited this entire series about the Malazan Book of the Fallen series of books'
},]
function Home(){
  return(
    <>
    <Hero/>
    <div id="work" className="container small work fade-in offset-3">
      {shows.map((show, index)=>{
        return <Show key={index} show={show} />
      })}
    </div>
    <div className="container projects small fade-in offset-3">
      <h4>Other Projects</h4>
      
      {projects.map((show, index)=>{
        return (
        <div key={index} target="_blank" rel="noreferrer" href={show.link} className="work-item project">
          <img src={show.img} alt={show.title}/>
          <div className="work-content">
            <div>
              <h3>{show.title}</h3>
              <img className="rect" alt="rectangle" src={rect}/>
              <p>{show.description}</p>
              <a href={show.link} target="_blank" rel="noreferrer"><button>Check it Out</button></a>
            </div>
          </div>
        </div>
        )
      })}
    </div>
    </>
  )
}

export default Home
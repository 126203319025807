import './Hero.scss'
function Hero() {
  return(
    <div className="container small hero">
      <h1 className="fade-in">Hey Gamers!</h1>
      <p className="fade-in offset-1">My name is AJ Fillari, and I've been producing <br/> and hosting podcasts for a while!</p>
      <p className="fade-in offset-2">Check 'em out below!</p>
    </div>
  )
}

export default Hero;